import { InitialState } from './app';

/**
 * @see https://umijs.org/docs/max/access#access
 * */
export default function access(initialState: InitialState) {
  const { userInfo } = initialState ?? {};
  const panelList = userInfo?.panel_list || [];
  return {
    // @ts-ignore
    canAdmin: (route) => !!panelList.find((panel) => panel === route.panelId),
  };
}
