import type { ProLayoutProps } from '@ant-design/pro-components';

/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  colorPrimary: '#1C00FF',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '',
  collapsed: false,
  pwa: true,
  logo: 'https://youlify.blob.core.windows.net/patient-portal/youlify_logo.svg',
  iconfontUrl: '',
  menu: {
    locale: false,
  },
  token: {
    sider: {
      colorMenuBackground: '#f4f4f4',
    },
    pageContainer: {
      colorBgPageContainer: '#ffffff',
    },
  },
};

export default Settings;
