// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';
import { mapValues, pick, omit, StringNullableChain } from 'lodash';

let BASE_URI = 'https://api.sandbox.youlify.ai/api';
// let BASE_URI = 'https://api.youlify.ai/api';
const hostname = window.location.hostname.toLowerCase();
const port = window.location.port;
if (hostname.includes('staging') || hostname.includes('demo')) {
  BASE_URI = 'https://api-staging.youlify.ai/api';
} else if (port === '8000') {
  // BASE_URI = 'http://0.0.0.0:8080/api';
}

/** Login to operation tool */
export function login(
  body: {
    username: string;
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.LoginRes>(`${BASE_URI}/operation-users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Logout of operation tool */
export function logout(
  body: {
    user_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.LoginRes>(`${BASE_URI}/operation-users/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get user profile information */
export function getProfileInfo(
  params: {
    user_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.ProfileInfoRes>(`${BASE_URI}/operation-users/${params.user_id}`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** Update user profile information */
export function updateProfileInfo(
  params: {
    user_id: string;
    name: string;
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<null>(`${BASE_URI}/operation-users/${params.user_id}`, {
    method: 'PUT',
    ...(options || {}),
  });
}

/** Send an automatic email for a new temporary password */
export function passwordReset(
  body: {
    email: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/operation-users/password-reset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get a list of practices (clinics) */
export function getPracticeList(
  params?: {
    user_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/practice/list`;
  return request<API.PracticeListRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a list of column available for filtering and their values */
export function getWorkQueueFilters(
  params?: {
    panel_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/work-queue/${params?.panel_id}/filters`;
  return request<API.WorkQueueFiltersRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a patient’s demographic information */
export async function getWorkQueueSearchList(
  params: {
    user_id: string;
    practice_id: string;
    panel_id: string;
    current?: number;
    pageSize?: number;
  } & Record<string, any>,
  sort: Record<string, any>,
  filters: Record<string, any>,
  options?: { [key: string]: any },
) {
  console.log('line 152', params, filters, sort);
  const url = `${BASE_URI}/work-queue/${params?.panel_id}/search`;
  const { user_id, practice_id, panel_id, patient_name: query = '', current, pageSize } = params;
  const omitFilters = omit(params, [
    'user_id',
    'practice_id',
    'panel_id',
    'patient_name',
    'current',
    'pageSize',
  ]);
  const sort_indices = mapValues(sort, (value) => (value === 'ascend' ? 1 : -1));
  return request<API.WorkQuerySearchRes>(url, {
    method: 'GET',
    params: {
      query,
      sort_indices,
      filters: omitFilters,
      user_id,
      practice_id,
      panel_id,
      current,
      pageSize,
    },
    ...(options || {}),
  });
}

/** Get a claim’s action information */
export function getClaimActionInfo(
  params?: {
    user_id: string;
    practice_id: string;
    panel_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/action-info`;
  return request<API.ClaimActionInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s basic information */
export function getClaimBasicInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/basic-info`;
  return request<API.ClaimBasicInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s payer information */
export function getClaimPayerInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/payer-info`;
  return request<API.ClaimPayerInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s surgery information */
export function getClaimSurgeryInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/surgery-info`;
  return request<API.ClaimSurgeryInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s coding information */
export function getClaimCodingInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/coding-info`;
  return request<API.ClaimCodingInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s eob information */
export function getClaimEobInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
    payer: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/eob-info`;
  return request<API.ClaimEobInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a claim’s submission information */
export function getClaimSubmissionInfo(
  params?: {
    user_id: string;
    practice_id: string;
    claim_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/claim/${params?.claim_id}/submission-info`;
  return request<API.ClaimSubmissionInfoRes>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Get a list of insurance names */
export function getInsuranceList(
  params?: {
    user_id: string;
  },
  options?: { [key: string]: any },
) {
  const url = `${BASE_URI}/insurance/list`;
  return request<API.APIRes<string[]>>(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Save a claim’s new coding information */
export function saveCodingInfoDraft(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    override_codes: object;
    reason: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/coding-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Submit a surgery claim’s coding information */
export function submitCodingInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/surgery-submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Save a claim’s payment information */
export function saveEobInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    payer: string;
    payer_result: object[];
    reason_code_description: object[];
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/eob-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Submit a claim’s EOB information */
export function submitEobInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/eob-submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Override patient information */
export function submitPatientInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    first_name: string;
    last_name: string;
    dob: string;
    primary_payer: {
      name: string;
      member_id: string;
    };
    secondary_payer: {
      name: string;
      member_id: string;
    };
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/patient-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Override claim information */
export function saveClaimInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    dos: string;
    submission_method: string;
    original_claim_id: string;
    facility_name: string;
    facility_code: string;
    admission_date: string;
    discharge_date: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/claim-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Submit a claim’s override information */
export function submitClaimInfo(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/override-submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Move a claim to claim review panel */
export function moveToQueue(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    queue_id: 'coding_review' | 'eob_posting' | 'denial' | 'claim_review';
    comment?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/move-to-queue`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Move a claim to claim review panel */
export function createInsurance(
  body: {
    user_id: string;
    payer_name: string;
    ehr_name: string;
    internal_name: string;
    short_name: string;
    payer_id: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/insurance/add-new`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Save appeal information for a denied claim */
export function denialAppeal(
  params: {
    claim_id: string;
  },
  body: {
    user_id: string;
    practice_id: string;
    method: string;
    appeal_text: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.APINullRes>(`${BASE_URI}/claim/${params.claim_id}/denial-appeal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
