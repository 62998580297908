import React from 'react';
import { flushSync } from 'react-dom';
import { getProfileInfo, getPracticeList } from '@/services/ant-design-pro/api';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import { Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { history, useModel } from '@umijs/max';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { Footer, AvatarDropdown, AvatarName } from '@/components';
import defaultSettings from '../config/defaultSettings';

const loginPath = '/user/login';

export type InitialState = {
  settings?: Partial<LayoutSettings>;
  userInfo?: API.UserInfo;
  profileInfo?: API.ProfileInfo;
  practiceInfo?: API.PracticeInfo;
  loading?: boolean;
  fetchProfileInfo?: () => Promise<API.ProfileInfo | undefined>;
  fetchPracticeInfo?: () => Promise<API.PracticeInfo | undefined>;
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<InitialState> {
  const getLocalStorageUserInfo = () => {
    try {
      const localUserInfoJSONStr = localStorage.getItem('youlify_user_info') || '';
      const userInfo = JSON.parse(localUserInfoJSONStr) as API.UserInfo;
      return userInfo;
    } catch (e) {
      history.push(loginPath);
    }
  };
  const fetchProfileInfo = async () => {
    try {
      const user_id = localStorage.getItem('youlify_user_id') ?? '';
      const res = await getProfileInfo({ user_id });
      const user_data = res.data;
      return user_data;
    } catch (e) {
      console.log(e);
      history.push(loginPath);
    }
    return undefined;
  };
  const fetchPracticeInfo = async () => {
    try {
      const user_id = localStorage.getItem('youlify_user_id') ?? '';
      const res = await getPracticeList({ user_id });
      const list = res.data;
      const practiceInfo = list?.length
        ? {
            id: list[0].practice_id,
            name: list[0].practice_name,
            list,
          }
        : undefined;
      return practiceInfo;
    } catch (e) {
      console.log(e);
      history.push(loginPath);
    }
    return undefined;
  };
  // 如果不是登录页面，执行
  const { location } = history;
  if (location.pathname !== loginPath) {
    const userInfo = getLocalStorageUserInfo();
    const profileInfo = await fetchProfileInfo();
    const practiceInfo = await fetchPracticeInfo();
    return {
      userInfo,
      profileInfo,
      practiceInfo,
      fetchProfileInfo,
      fetchPracticeInfo,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchProfileInfo,
    fetchPracticeInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const CustomHeaderTitle = () => {
    const { initialState, setInitialState } = useModel('@@initialState');
    const practiceInfo = initialState?.practiceInfo;
    const practiceList = practiceInfo?.list || [];
    const menuItems = practiceList.map((practice) => ({
      key: practice.practice_id,
      label: practice.practice_name,
    }));
    const onMenuClick = ({ key }: { key: string }) => {
      const name =
        practiceList.find((practice) => practice.practice_id.toString() === key)?.practice_name ||
        '';
      flushSync(() => {
        setInitialState((s) => ({
          ...s,
          practiceInfo: {
            ...practiceInfo,
            id: key,
            name,
          },
        }));
      });
    };
    return practiceInfo ? (
      <div>
        <Dropdown arrow={true} menu={{ items: menuItems, onClick: onMenuClick }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '20px 0',
              color: '#000000',
              fontSize: '16px',
            }}
          >
            <div>{practiceInfo?.name}</div>
            <DownOutlined style={{ fontSize: '12px', marginLeft: 4 }} />
          </div>
        </Dropdown>
      </div>
    ) : null;
  };

  return {
    actionsRender: () => [],
    avatarProps: {
      icon: <Avatar icon={<UserOutlined />} />,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    menuExtraRender: () => <CustomHeaderTitle />,
    collapsedButtonRender: () => null,
    footerRender: () => <Footer />,
    pageTitleRender: (props, defaultPageTitle) => {
      const pathname = props.pathname;
      const matchedPath = pathname?.match(/\//g) || [];
      if (matchedPath.length > 1) {
        const pathId = pathname?.split('/')?.[2];
        return `Claim #${pathId}`;
      }
      return defaultPageTitle || '';
    },
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.profileInfo?.name && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    menuHeaderRender: undefined,
    // 自定义 403 页面
    unAccessible: <div>You do not have permission to view this page (403)</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      return <>{children}</>;
    },
    ...initialState?.settings,
  };
};
